import React from "react"

import styles from "./content.module.scss"
import { AccountGuard } from "../../../../guards/account.guard"

const ContentPage6 = () => {
  return (
    <AccountGuard>
      <div className={`${styles.header}`}>
        <h2>Technical Official Introductory</h2>
      </div>

      <div className={`${styles.wrapper}`}>
        <div className={`${styles.sectionHeader}`}>
          <h3>Section 06</h3>
          <h1>During The Event</h1>
        </div>
      </div>

      <div className={`${styles.contentWrapper}`}>
        <ul className={`${styles.content}`}>
          <li>
            <p>
              <b>Command & Control</b>
            </p>

            <p>
              The competition floor is a shared spaced between Technical
              Officials and athletes. This area should be controlled by the
              Technical Officials to ensure proper safety requirements are
              followed as well as to mitigate against undue influence of the
              athlete’s performance.
            </p>

            <p>Common issues to avoid:</p>

            <p>
              <ul>
                <li>
                  <b>Crowding:</b> Standing too close as an athlete completes a
                  task. This may prohibit an athlete from resting or stopping
                  when they normally would have.
                </li>
                <li>
                  <b>Herding:</b> Unless it is for immediate safety concerns a
                  Technical Official should never intentionally physically touch
                  or move an athlete
                </li>
                <li>
                  <b>Equipment Shepherding:</b> Do not move equipment for an
                  athlete unless it presents an immediate safety concern.
                </li>
                <li>
                  <b>Pacing:</b> Technical Officials should minimize their
                  physical movement. This includes unnecessary movement while
                  counting or reviewing athlete performance.
                </li>
                <li>
                  <b>Slow Calls:</b> Make timely decisions and avoid a delayed
                  count or “no-rep” in order to avoid confusion
                </li>
                <li>
                  <b>Clutter:</b> Weight belts, bottles, equipment, chalk, and
                  any non-essential items should not be allowed to change or
                  congest the field of play
                </li>
              </ul>
            </p>

            <p>
              <b>Best Intentions</b>
            </p>

            <p>
              A Technical Official’s best intentions can lead to confusion and
              delays. It is critical to the integrity of the sport, that our
              behavior is professional — cordial but purely objective and
              unaffected by the result.
            </p>

            <p>Athlete communication</p>

            <p>
              <ul>
                <li>
                  <b>Introductions are accepted but not expected:</b> If an
                  athlete introduces themselves, it is expected that a Technical
                  Official respond in kind, but not offer additional
                  conversation
                </li>
                <li>
                  <b>Don’t recommunicate the standards:</b> Athletes should be
                  referred to the Head Technical official or team coaches for
                  any questions. The start of the heat is not the time to ask
                  these questions.
                </li>
                <li>
                  <b>Don’t be a cheerleader:</b> An athlete has teammates and
                  crowd support. A technical official’s responsibilities require
                  full attention and should not be reduced in order to offer
                  support.
                </li>
                <li>
                  <b>Don’t be a super fan:</b> Impartiality is critical to a
                  Technical Officials performance. Regardless of previous or
                  current performance, all athletes should receive the same
                  objective treatment from all Technical Officials.
                </li>
                <li>
                  <b>Don’t be a coach:</b> An athlete’s performance is based on
                  their ability to execute according to their plan. Technical
                  Officials should offer no insight, strategy, or tips in order
                  to support an athlete.
                </li>
              </ul>
            </p>

            <p>Non-Competitors / Team Staff Communication</p>

            <p>
              <ul>
                <li>Redirect coaches to Head Technical Officials</li>
              </ul>
            </p>

            <p>Spectator Communication</p>

            <p>
              <ul>
                <li>
                  Kept to an absolute minimum and permitted only when necessity
                  requires
                </li>
              </ul>
            </p>

            <p>
              <i>
                Under no circumstances, should a Technical Official publicly
                question or disparage a decision or the performance of another
                Technical Official. If a situation arises that warrants
                intervention, refer all issues to a Head Technical Official for
                the event as soon as it is possible and appropriate.
              </i>
            </p>

            <p>
              <b>Visual & Verbal</b>
            </p>

            <p>
              Technical Officials’ duties include the proper communication to
              their respective athlete as well as to others interested in an
              athlete’s performance. It is critical that key gestures and verbal
              queues are properly executed.
            </p>

            <p>
              <ul>
                <li>No Rep</li>
                <li>Help</li>
                <li>Final Five</li>
                <li>Successful Completion for Max Effort lifts only</li>
              </ul>
            </p>

            <p>
              <b>“ASIFF”</b>
            </p>

            <p>
              A Technical Official can be negatively impacted when he / she
              allows different emotions, rather than the prescribed standards,
              to dictate how performance is assessed.
            </p>

            <p>
              <ul>
                <li>
                  <b>Apathy:</b> Not caring about your responsibilities
                </li>
                <li>
                  <b>Sympathy:</b> Giving undue credit based on lack of
                  objectivity
                </li>
                <li>
                  <b>Ignorance:</b> Not knowing what should be occurring
                </li>
                <li>
                  <b>Fear:</b> Being afraid to assert oneself
                </li>
                <li>
                  <b>Fatigue:</b> Losing mental and physical acuity due to
                  exertion and stress
                </li>
              </ul>
            </p>

            <p>
              <b>Special Circumstances</b>
            </p>

            <p>
              Technical Officials are obliged to inform Head Officials of
              factors that may actually influence the outcome of a test or
              create the impression of anything other than complete
              impartiality.
            </p>

            <p>
              <ul>
                <li>
                  <b>Officiating Errors:</b> Immediately notify Head Technical
                  Official in a discrete manner providing as much insight into
                  the issue as possible.
                </li>
                <li>
                  <b>Conflicts of Interest:</b> Technical Officials must
                  disclose existing personal relationships with any athletes or
                  teams prior to the beginning of events. Violations may result
                  in revocation of Technical Official’s certification(s).
                </li>
              </ul>
            </p>
          </li>
        </ul>

        <div className={`${styles.buttons}`}>
          <div className={`${styles.previous}`}>
            <a href="/courses/toi/content/5">
              <i
                className={`fas fa-arrow-alt-circle-left uk-margin-small-right`}
              />
              Previous
            </a>
          </div>
          <div className={`${styles.next}`}>
            <a href="/courses/toi/content/7">
              Next
              <i
                className={`fas fa-arrow-alt-circle-right uk-margin-small-left`}
              />
            </a>
          </div>
        </div>
      </div>
    </AccountGuard>
  )
}

export default ContentPage6
